import { FC } from 'react';
import { TCharLineProps } from './types';

export const ChartLine: FC<TCharLineProps> = ({ color, width, hoverHandler, unHoverHandler }) => {
	return (
		<div
			style={{
				marginBottom: '20px',
				backgroundColor: color,
				width,
				display: 'inline-block',
			}}
			onMouseOver={hoverHandler}
			onFocus={hoverHandler}
			onMouseOut={unHoverHandler}
			onBlur={unHoverHandler}
		>
			&nbsp;
		</div>
	);
};
