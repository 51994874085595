import { FC } from "react";
import { XScaleItem } from "./XScaleItem";
import { TXScaleProps } from "./types";

export const XScale: FC<TXScaleProps> = ({ sArray, height, scaleX }) => {
  return (
    <div
      style={{
        position: "absolute",
        left: "55px",
        top: 0,
        height,
        width: "100%",
      }}
    >
      {sArray.map((item, index) => (
        <XScaleItem
          key={`sArray${item}-${index}`}
          left={`${(item * 100) / scaleX}%`}
          num={new Intl.NumberFormat("en-EN", {
            style: "currency",
            currency: "EUR",
          }).format(item)}
        />
      ))}
    </div>
  );
};
