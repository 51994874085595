export const COLOR_ARRAY = [
	'#e1be0f',
	'#b399ff',
	'#a2e383',
	'#e2b5ea',
	'#cfdd1c',
	'#6ec1e4',
	'#8bd8b2',
	'#ff7fb4',
	'#fab768',
	'#e27272',
	'#a19090',
	'#016193',
];

export const MONTH_ARRAY = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

export const SCALE_COEFFICIENT_MAX = 0.1;
export const SCALE_COEFFICIENT_MIN = 0.12;
