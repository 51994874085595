/* eslint-disable no-nested-ternary */
import { FC } from 'react';
import { RectangleDown } from './RectangleDown';
import { RectangleUp } from './RectangleUp';
import { TOrdersItemProps } from './types';

export const OrdersItem: FC<TOrdersItemProps> = ({ text, amount, percent }) => (
	<div
		style={{
			width: '50%',
			textAlign: 'center',
		}}
	>
		<div>{text}</div>
		<div
			style={{
				fontSize: '26px',
				fontWeight: 'bold',
				color: '#016193',
			}}
		>
			{amount}
		</div>
		<div
			style={{
				// display: 'flex',
				flexDirection: 'column',
				justifyContent: 'inherit',
				color: `${percent > 0 ? 'green' : percent < 0 ? 'red' : 'black'}`,
				textAlign: 'center',
			}}
		>
			{percent > 0 ? <RectangleUp /> : percent < 0 ? <RectangleDown /> : null}
			<span>{Math.abs(percent)}%</span>
		</div>
	</div>
);
