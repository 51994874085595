import { Switch } from "antd";
import { FC } from "react";
import { ChartLegend } from "./ChartLegend";
import { TChartFooterProps } from "./types";

export const ChartFooter: FC<TChartFooterProps> = ({
  showDetailedData,
  setShowDetailedDataHandler,
  charts,
  colors,
}) => {
  return (
    <div
      style={{
        marginLeft: "55px",
        marginTop: "20px",
        padding: "0px",
      }}
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Switch onChange={setShowDetailedDataHandler} />
        <div style={{ marginLeft: "10px" }}>Show detailed data</div>
      </div>
      {showDetailedData && <ChartLegend charts={charts} colors={colors} />}
    </div>
  );
};
