import { FC } from 'react';

export const QuoteSvg: FC = () => (
	<svg
		width="80px"
		height="80px"
		version="1.1"
		viewBox="0 0 8466.66 8466.66"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g>
			<path
				className="fil0"
				d="M3413.49 485.73c2130.33,478.35 1546.37,-407.62 1798.95,1681.42 57.03,471.76 230.97,3098.33 -76.27,3300.44 -953.87,627.48 -1037.1,-1436.46 -1189.02,-2101.26 -148.88,-651.55 -607.37,-2311.32 -533.66,-2880.6zm-441.83 -414.06c-61.61,770.1 462.59,2718.92 649.09,3580.19 277.44,1281.19 168.56,2931.48 1846.09,2130.43 434.45,-207.48 125.17,-3388.98 119.91,-3994.96 -7.64,-879.29 298.4,-1373.53 -732.14,-1461.2 -626.61,-53.33 -1223.02,-197.62 -1882.95,-254.46z"
			/>
			<path
				className="fil0"
				d="M2639.36 1355.87c379.48,-907.71 -4222.42,924.59 -1937.15,4075.08 973.49,1342.06 1350.26,859.77 -329.81,2007.17 -845.6,577.46 1728.13,772.84 2583.47,-4.25 309.98,-281.63 225.33,-387.4 586.89,-284.27 349.89,99.76 553.7,-166.53 189.03,-371.75 -598.1,-336.62 -790.98,152.48 -1187.43,437.02 -227.58,163.36 -1413.06,480.76 -1090.58,9.43 265.7,-388.32 835.32,-695.16 359.56,-1296.65 -628.96,-795.13 -1584.98,-1230.74 -1246.49,-2638.36 374.28,-1556.25 1926,-1582.94 2072.51,-1933.42z"
			/>
			<path
				className="fil0"
				d="M5937.47 1446.65c-200.58,612.11 1797.5,1014.46 1997.46,2695.05 92.56,778.11 -53.18,1297.29 -618.39,1839.75 -189.89,182.22 -1628.95,923.97 -884.99,949.86 1172.89,40.8 2153.33,-1810.71 1902.47,-2986.86 -237.06,-1111.68 -1296.36,-2228.56 -2396.55,-2497.8z"
			/>
			<path
				className="fil0"
				d="M4698.99 7137.4c-4.68,-167.84 19.8,-134.22 -1.66,-235.06 120.05,-59.89 527.95,-62.36 673.04,-24.7l-17.21 580.64c-128.34,37.94 -450.98,43.17 -569.49,-16.07 -62.85,-118.12 -81.08,-177.45 -84.68,-304.81zm1055.2 722.35c120.06,-162 126.4,-1278.17 -35.47,-1397.21 -147.89,-108.73 -1197.8,-92.01 -1394.47,78.83 -183.82,159.65 1.36,993.55 128.01,1188.9 69.03,106.45 1097.6,280.94 1301.93,129.48z"
			/>
		</g>
	</svg>
);
