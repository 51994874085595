import { FC } from "react";
import { ChartLines } from "./ChartLines";
import { XScale } from "./XScale";
import { YScale } from "./YScale";
import { TChartBodyProps } from "./types";

export const ChartBody: FC<TChartBodyProps> = ({
  chart,
  showDetailedData,
  hoverHandler,
  unHoverHandler,
  scaleX,
  sArray,
  colors,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        position: "relative",
        height: `${chart.length * 41 + 21}px`,
        overflow: "hidden",
      }}
    >
      <YScale chart={chart} />
      <ChartLines
        chart={chart}
        showDetailedData={showDetailedData}
        hoverHandler={hoverHandler}
        unHoverHandler={unHoverHandler}
        scaleX={scaleX}
        colors={colors}
      />
      <XScale
        sArray={sArray}
        height={`${chart.length * 41 + 20}px`}
        scaleX={scaleX}
      />
    </div>
  );
};
