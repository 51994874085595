import { FC } from "react";
import { TChartLegendItemProps } from "./types";

export const ChartLegendItem: FC<TChartLegendItemProps> = ({
  name,
  index,
  color,
}) => {
  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          backgroundColor: `${color}`,
          height: "20px",
          marginRight: "10px",
          borderRadius: "5px",
          width: "100px",
        }}
      />
      <div>{name}</div>
    </div>
  );
};
