import { FC } from "react";
import { ChartLine } from "./ChartLine";
import { COLOR_ARRAY } from "../../../constants";
import { TChartLinesProps } from "./types";

export const ChartLines: FC<TChartLinesProps> = ({
  chart,
  showDetailedData,
  scaleX,
  hoverHandler,
  unHoverHandler,
  colors,
}) => {
  const arrayX = chart.map((item) =>
    item.x.reduce((acc, num) => acc + num.amount, 0)
  );

  return (
    <div
      style={{
        width: "100%",
        fontSize: "14px",
        borderLeft: "1px solid grey",
        borderBottom: "1px solid grey",
        position: "absolute",
        left: "55px",
        zIndex: 1000,
        height: `${chart.length * 41 + 20}px`,
      }}
    >
      {showDetailedData
        ? chart.map((item, index) => (
            <div key={`chart${item.y}-${index}`}>
              {item.x.map((itemX, indexX) => (
                <ChartLine
                  key={`ChartLine0${item.y}-${itemX.name}`}
                  color={colors[itemX.name]}
                  width={`${(itemX.amount * 100) / scaleX}%`}
                  hoverHandler={() =>
                    hoverHandler(index, indexX, itemX.amount, itemX.name)
                  }
                  unHoverHandler={unHoverHandler}
                />
              ))}
              <span className="chart--amount" style={{ marginLeft: "5px" }}>
                {new Intl.NumberFormat("en-EN", {
                  style: "currency",
                  currency: "EUR",
                }).format(arrayX[index])}
              </span>
            </div>
          ))
        : arrayX.map((item, index) => (
            <div key={`${item}-${index}`}>
              <ChartLine
                color={COLOR_ARRAY[COLOR_ARRAY.length - 1]}
                width={`${(item * 100) / scaleX}%`}
                hoverHandler={() =>
                  hoverHandler(index, COLOR_ARRAY.length - 1, item, "")
                }
                unHoverHandler={unHoverHandler}
              />
              <span className="chart--amount" style={{ marginLeft: "5px" }}>
                {new Intl.NumberFormat("en-EN", {
                  style: "currency",
                  currency: "EUR",
                }).format(arrayX[index])}
              </span>
            </div>
          ))}
    </div>
  );
};
