import { FC } from "react";

import totalTaxes from "../api/totalTaxes.json";
import { MONTH_ARRAY } from "../constants";
import { QuoteSvg } from "./QuoteSvg";
import "./styles.css";

const dateToday = new Date();

export const TotalTaxes: FC = () => (
  <div>
    <h2>
      Total Taxes Collected for{" "}
      {`${MONTH_ARRAY[dateToday.getUTCMonth()]} ${dateToday.getUTCFullYear()}`}
    </h2>
    <div className="total-taxes--grid">
      <div
        style={{
          fontSize: "26px",
          fontWeight: "bold",
          color: "#016193",
          marginRight: "40px",
        }}
      >
        {new Intl.NumberFormat("de-DE", {
          style: "currency",
          currency: "EUR",
        }).format(totalTaxes.totalTaxes)}
      </div>
      <div
        style={{
          display: "flex",
        }}
      >
        <div
          style={{
            marginRight: "20px",
            fill: "#016193",
          }}
        >
          <QuoteSvg />
        </div>
        <div>
          Please notice. This is approximate amount of taxes to be paid to
          Authorities. Accurate amount of taxes will be available at the
          beginning of next month in the Fiscal VAT reports.
          <br />
          During VAT reports generation special Exchange rates, provided by EU
          and UK authorities, will be applied, corrections and returns will be
          taken into account as well.
        </div>
      </div>
    </div>
  </div>
);
