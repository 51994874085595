import { FC } from 'react';
import './styles.css';
import { TXScaleItemProps } from './types';

export const XScaleItem: FC<TXScaleItemProps> = ({ left, num }) => (
	<>
		<div className="vertical-line" style={{ left }} />
		<div className="vertical-line-num" style={{ left: `calc(${left} + 5px)` }}>
			{num}
		</div>
	</>
);
