import { FC } from 'react';
import { Chart } from './Chart';
import { SalesInformation } from './SalesInformation';
import { TotalTaxes } from './TotalTaxes';
import './index.css';

export const Graph: FC = () => (
	<div className="eas-taxes-end-sales--main">
		<TotalTaxes />
		<SalesInformation />
		<Chart />
	</div>
);
