import { FC } from "react";
import { OrdersItem } from "./OrdersItem";
import salesInformation from "../api/salesInformation.json";

export const SalesInformation: FC = () => (
  <div style={{ marginTop: "40px" }}>
    <hr />
    <h2 style={{ marginBottom: 0 }}>Sales Handled by EAS</h2>
    <p style={{ marginTop: 0 }}>
      <small style={{ color: "gray" }}>
        Date range: the last 30 days | Compared to: Previous 30 days
      </small>
    </p>
    <div
      style={{
        marginTop: "20px",
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(380px, 1fr))",
        gridTemplateRows: "repeat(4, auto-fit)",
        gridGap: "1rem",
        gridAutoFlow: "row dense",
      }}
    >
      {salesInformation.map((item, index) => {
        if (
          (item?.orderAmount ?? 0) +
          (item?.orderAmountPercent ?? 0) +
          (item?.orderCount ?? 0) +
          (item?.orderCountPercent ?? 0)
        )
          return (
            <div
              key={`${item.name}-${index}`}
              style={{
                border: "1px solid #f7f7f7",
                borderRadius: "5px",
                padding: "10px",
                background: "#f7f7f7",
              }}
            >
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {item.name}
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <OrdersItem
                  text="Orders Count"
                  amount={String(item.orderCount)}
                  percent={item.orderCountPercent}
                />
                <OrdersItem
                  text="Orders Amount"
                  amount={new Intl.NumberFormat("en-EN", {
                    style: "currency",
                    currency: "EUR",
                  }).format(item.orderAmount)}
                  percent={item.orderAmountPercent}
                />
              </div>
            </div>
          );
        return null;
      })}
    </div>
  </div>
);
