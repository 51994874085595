import { FC } from "react";
import { ChartLegendItem } from "./ChartLegendItem";
import { TChartLegendProps } from "./types";

export const ChartLegend: FC<TChartLegendProps> = ({ charts, colors }) => {
  return (
    <div
      style={{
        marginTop: "25px",
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(250px, 2fr))",
        gridTemplateRows: "repeat(2, auto-fit)",
        gridGap: "1rem",
        gridAutoFlow: "dense",
      }}
    >
      {charts.map((item, index) => (
        <ChartLegendItem
          key={`${item}-${index}`}
          name={item}
          index={index}
          color={colors[item]}
        />
      ))}
    </div>
  );
};
