/* eslint-disable no-nested-ternary */
import { FC, useEffect, useMemo, useState } from "react";
import { Tooltip } from "./Tooltip";
import chart from "../api/chart2.json";
import {
  COLOR_ARRAY,
  SCALE_COEFFICIENT_MAX,
  SCALE_COEFFICIENT_MIN,
} from "../constants";
import "./styles.css";
import { ChartFooter } from "./ChartFooter";
import { ChartBody } from "./ChartBody";
import { TChart } from "../api/types";

export const Chart: FC = () => {
  const [documentWidth, setDocumentWidth] = useState(window.innerWidth);
  const [namePartOfLine, setNamePartOfLine] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);
  const [topPositionTooltip, setTopPositionTooltip] = useState(0);
  const [leftPositionTooltip, setLeftPositionTooltip] = useState(0);
  const [showDetailedData, setShowDetailedData] = useState(false);
  const [amountTooltip, setAmountTooltip] = useState("0");

  const allChartArrayFoo = (chrt: TChart) => {
    const allChartTmp: string[] = [];
    for (let i = 0; i < chrt.length; i++) {
      for (let j = 0; j < chrt[i].x.length; j++) {
        if (!allChartTmp.includes(chrt[i].x[j].name))
          allChartTmp.push(chrt[i].x[j].name);
      }
    }
    return allChartTmp;
  };

  const allChartColorFoo = (chrt: string[]) => {
    const colors: any = {};
    for (let i = 0; i < chrt.length; i++) {
      colors[chrt[i]] = COLOR_ARRAY[i];
    }
    return colors;
  };

  const allChartArray = allChartArrayFoo(chart);
  const chartColors = useMemo(
    () => allChartColorFoo(allChartArray),
    [allChartArray]
  );
  console.log("chartColor", chartColors);

  useEffect(() => {
    function handleResize() {
      setDocumentWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
  });
  const scaleCoefficient =
    documentWidth < 1000
      ? 0
      : documentWidth < 1400
      ? SCALE_COEFFICIENT_MIN
      : SCALE_COEFFICIENT_MAX;
  const arrayX = chart.map((item) =>
    item.x.reduce((acc, num) => acc + num.amount, 0)
  );

  const maxX = Math.max.apply(null, arrayX);
  const maxD = Math.ceil(maxX + scaleCoefficient * maxX);
  const razr = Math.ceil(maxD).toString().length - 1;
  const scaleX = Math.ceil(maxD / 10 ** razr) * 10 ** razr;
  const hRazr = +Math.ceil(scaleX).toString()[0];
  const mRazr = scaleX / hRazr;
  const sArray: number[] = [];
  if (documentWidth < 1000 || hRazr > 3)
    for (let i = 0; i < hRazr; i += 1) {
      sArray.push(i * mRazr);
    }
  else
    for (let i = 0; i < hRazr * 4; i += 1) {
      sArray.push((i * mRazr) / 4);
    }

  const setShowDetailedDataHandler = (checked: boolean) => {
    setShowDetailedData(checked);
  };

  const hoverHandler = (
    index: number,
    num: number,
    amount: number,
    name: string
  ) => {
    if (!amount) return;
    setShowTooltip(true);
    setTopPositionTooltip(38 + 41 * index);
    setNamePartOfLine(name);
    let shiftX = 0;
    if (showDetailedData) {
      setAmountTooltip(
        `${chart[index].x[num].name}: ${new Intl.NumberFormat("en-EN", {
          style: "currency",
          currency: "EUR",
        }).format(amount)}`
      );
      for (let i = 0; i <= num; i += 1) {
        if (i > 0) {
          shiftX += (chart[index].x[i - 1].amount * 100) / scaleX;
        }
      }
      shiftX += (chart[index].x[num].amount * 10) / scaleX;
    } else {
      setAmountTooltip(
        `${new Intl.NumberFormat("en-EN", {
          style: "currency",
          currency: "EUR",
        }).format(amount)}`
      );
      shiftX += (amount * 10) / scaleX;
    }
    setLeftPositionTooltip(shiftX);
  };

  const unHoverHandler = () => {
    setShowTooltip(false);
  };

  return (
    <div style={{ marginTop: "40px", position: "relative" }}>
      <hr />
      <h2>Total Sales Amount</h2>
      <ChartBody
        chart={chart}
        showDetailedData={showDetailedData}
        hoverHandler={hoverHandler}
        unHoverHandler={unHoverHandler}
        scaleX={scaleX}
        sArray={sArray}
        colors={chartColors}
      />
      <ChartFooter
        showDetailedData={showDetailedData}
        setShowDetailedDataHandler={setShowDetailedDataHandler}
        charts={allChartArray}
        colors={chartColors}
      />
      <Tooltip
        show={showTooltip}
        topPosition={topPositionTooltip}
        color={
          showDetailedData
            ? chartColors[namePartOfLine]
            : COLOR_ARRAY[COLOR_ARRAY.length - 1]
        }
        amount={amountTooltip}
        leftPosition={leftPositionTooltip}
      />
    </div>
  );
};
