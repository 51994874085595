import { FC } from "react";
import { RectangleDown } from "./RectangleDown";
import { TTooltipProps } from "./types";

export const Tooltip: FC<TTooltipProps> = ({
  show,
  topPosition,
  color,
  amount,
  leftPosition,
}) => {
  return (
    <div
      style={{
        display: `${show ? "block" : "none"}`,
        position: "absolute",
        top: `${String(topPosition)}px`,
        left: `calc(${String(leftPosition)}% + 40px)`, // '60px',
        zIndex: 4000,
      }}
    >
      <RectangleDown color={color} />
      {leftPosition > 50 && amount.length > 40 ? (
        <div
          style={{
            backgroundColor: `${String(color)}`,
            padding: "5px 10px",
            borderRadius: "5px",
            display: "inline-block",
            color: "white",
            fontWeight: "bold",
            position: "absolute",
            top: "-20px",
            whiteSpace: "nowrap",
            boxShadow: "0 0 10px #00000080",
            fontSize: "14px",
            right: `calc(${String(leftPosition)}% - 40px)`,
          }}
        >
          {amount}
        </div>
      ) : (
        <div
          style={{
            backgroundColor: `${String(color)}`,
            padding: "5px 10px",
            borderRadius: "5px",
            display: "inline-block",
            color: "white",
            fontWeight: "bold",
            position: "absolute",
            top: "-20px",
            whiteSpace: "nowrap",
            boxShadow: "0 0 10px #00000080",
            fontSize: "14px",
            // right: `calc(${String(leftPosition)}% - 40px)`,
          }}
        >
          {amount}
        </div>
      )}
    </div>
  );
};
