import { FC } from 'react';
import './App.css';
import { Graph } from 'components/Graph';
import 'antd/dist/antd.css';

const App: FC = () => {
	return (
		<div style={{ padding: '40px' }}>
			<Graph />
		</div>
	);
};

export default App;
