import { FC } from "react";
import { TYScaleProps } from "./types";

export const YScale: FC<TYScaleProps> = ({ chart }) => {
  return (
    <div
      style={{
        width: "55px",
        fontSize: "14px",
      }}
    >
      {chart.map((item, index) => (
        <div key={`y${item.y}-${index}`} style={{ marginBottom: "20px" }}>
          {item.y}
        </div>
      ))}
    </div>
  );
};
